::-webkit-file-upload-button {
  display: none;
}

.image-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px dashed var(--primary-blue);
  aspect-ratio: 2;
  background-color: var(--image-card-back);
  border-radius: 8px;
  color: transparent;
  // flex: 0 0 10%;
  // height: 200px;
  width: 240px;
}

.input-tag {
  display: none;
}

.preview-wrapper {
  width: 100%;
  height: 150px;
  overflow: hidden;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.preview-image {
  width: 100%;
  // max-height: 150px;
}

.delete-icon {
  position: absolute;
  top: 3px; /* Slightly above the container */
  right: 4px; /* Slightly to the right of the container */
  z-index: 100; /* Ensure it stays above other elements */
  width: 25px; /* Adjust size if necessary */
}

.delete-icon:hover {
  cursor: pointer;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
  gap: 10px;
  cursor: pointer;
}

.add-icon {
  width: 25px;
  object-fit: contain;
  cursor: pointer;
}

.text {
  font-size: 14px;
  color: var(--image-card-text);
  font-weight: 400;
  text-align: center;
  padding: 0 5px 10px 10px;
}

@media screen and (max-width: 1024px) {
  .image-container {
    flex: 0%;
  }
}

.submit-btn {
  display: flex;
  justify-content: flex-end;
}
