.mid-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 20px);

  background: #ffffff;
  border-radius: 5px;

  min-height: 75vh;
  height: auto;
}

.selectable-button-container-style {
  width: 100%;
}
.dropdown-container {
  width: 100%;
  max-width: 540px;
}
.price-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.error-text {
  color: red;
  font-size: 14px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 20px);
  background: #ffffff;
  border-radius: 5px;
  margin-top: 20px;
  min-height: 75vh;
  height: auto;
}

h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.two-tabs-section {
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
}

.input-label {
  margin-bottom: 10px;
  width: 100%;
  display: flex;

  align-items: start;
}

.label {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  color: #555;
  min-width: 150px; /* Fixed width for labels to align with inputs */
  text-align: left; /* Align text to the right */
  margin-right: 20px;
}

select,
input {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  max-width: 150px; /* Ensure the input fields have a consistent width */
}

input[type="number"] {
  max-width: 150px;
  margin-right: 10px;
}

input[type="number"]:last-of-type {
  margin-right: 0; /* Remove margin from the last input in the row */
}

.-span {
  margin-right: 10px;
}

.input-container {
  width: 100%;
  min-width: 400px;
  max-width: 800px;
}

.dealer-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding: 20px 40px 20px 40px;
  background-color: #f9f9f9;
  .wrapper {
    .heading {
      color: #918f8f;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 0px;
    }
    .value {
      font-size: 16px;
      font-weight: 700;
      color: #007abe;
      padding-left: 30px;
    }
  }
}

.heading {
  margin-bottom: 50px;
  margin-left: 30px;
}

/* New Styles for Additional Comments and Save Button */
.textarea {
  width: 100%;
  height: 150px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  max-width: 500px;
}

//   .save-button-section {
//     position: fixed;
//     right: 40px;
//     bottom: 20px;
//   }

.save-button-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 5px;
  gap: 10px;
}

.save-button {
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #121b5b;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #121b5b;
}

.dealer-info-container {
  background-color: #ffffff;
  border-radius: 10px;
  margin-left: 25px;
}

.dealer-info-text {
  font-size: 18px;
  font-weight: 500;
  // color: #333333;
  color: black;
  line-height: 1.5;
}
