.header-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
}
.first-heading{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.select-wraapper{
    width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sel-wrapper{
    width: 400px;
}
.input-wrapper{
    margin-top: 10px;
}
.ans{
    margin-bottom: 10px;
}

.editor {
    min-height: 400px;
}
.action-wrapper{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}