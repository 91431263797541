.vehicle-table-container {
  padding: 5px;
  border-radius: 12px;
  box-shadow: 6px 4px 54px 0 #0000000d;
  background-color: var(--white);
  width: 100%;
  // border: solid 1px black;
}

.table-header-wrapper {
  background-color: #f1f4f9;
}

.addCommentText {
  margin-left: 5px;
  font-size: 14px;
  cursor: pointer;
  color: #007bff; /* or any color of your choice */
}

.pref-button {
  width: 30px;
  height: 30px;
  padding-bottom: 2px;
  cursor: pointer;
}

.addCommentButton {
  margin-left: 5px;
  border: none;
  background-color: transparent;
  margin-right: 5px;
  margin-bottom: 0px;
}

.calendarIcon {
  margin-left: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}

.processButton {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 8px;
  margin-right: 5px;
  border-radius: 5px;
}

.table-header-wrapper {
  color: var(--table-header-black);
  background-color: var(--table-header-back);
  border-radius: 120px !important;
  padding: 12px;
  // border: solid 1px  blue;
}

.table-header {
  font-size: 14px;
  font-weight: 600;
  background-color: #f1f4f9 !important;
  border-bottom: 0px;
}

.table-body {
  color: var(--sidebar-text-gray);
}

.table-row {
  font-size: 0.875rem;
  color: var(--original-price);
}

/* Container for the comments */
.commentsContainer {
  overflow-y: auto;
  // padding: 5px;
  background-color: #f9f9f9; /* Light grey background */
  // border: 1px solid #e0e0e0;
  border-radius: 8px;
}

/* Each comment block */
.commentBlock {
  margin-bottom: 20px;
}

/* Styling the comment text */
.commentText {
  font-size: 16px;
  line-height: 1.5;
  color: #333; /* Dark text for readability */
  margin-bottom: 5px; /* Space between comment and date */
  background-color: #ffffff; /* White background for comment */
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Styling the date and time */
.dateText {
  font-size: 14px;
  color: #888;
  margin-top: 5px;
  font-style: Roboto;
}

.divider {
  margin-top: 15px;
  border: 1px solid #ccc;
  height: 1px;
  background-color: #ccc;
}
