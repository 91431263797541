.container {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: var(--car-detail-back);
   display: flex;
   justify-content: end;
   transition: transform 1s ease-in-out;
   transform: translateX(0);
}

.wrapper {
   width: 45%;
   background-color: var(--white);
   padding: 25px 70px;
}

.header-container {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 30px;
}

.heading-wrapper {
   display: flex;
   column-gap: 15px;
   align-items: center;
}

.primary-button-container-style {
   padding: 7px 26px;
   background-color: var(--primary-blue);
}

.primary-image-wrapper {
   width: 100%;
}

.primary-image {
   border-radius: 8px;
   width: 100%;
   object-fit: contain;
}

.images-carousel-container {
   width: 100%;
   display: flex;
   column-gap: 30px;
   margin: 30px 0 40px 0;
   overflow: hidden;
   align-items: center;
   position: relative;
}

.back-button {
   position: absolute;
   left: 0;
   top: 41px;
   width: 48px;
   object-fit: contain;
   transform: translateX(-24px);
}

.image-carousel-wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.carousel-image {
   width: 130px;
   height: 130px;
   border-radius: 8px;
   object-fit: cover;
}

.image-type {
   color: var(--image-text);
   margin-top: 8px;
   font-size: 0.875rem;
}

.forward-button {
   position: absolute;
   right: 0;
   top: 41px;
   width: 48px;
   object-fit: contain;
   transform: translateX(24px);
}

.vehicle-name {
   font-size: 2.25rem;
   font-weight: 600;
   color: var(--vehicle-name);
   margin-top: 40px;
}