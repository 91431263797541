.container {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--border-gray);
  border-radius: 4px;
  // padding: 8px 12px;
  width: 100%;
}

.select-tag {
  width: calc(100% - 24px);
  outline: none;
  border: none;
  appearance: none;
  background-color: transparent;
  color: #333;
  font-size: 14px;
  padding-right: 24px;
  padding: 8px 12px;
}

.select-tag::placeholder {
  color: #999;
}

.down-arrow-box {
  position: absolute;
  right: 12px;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.drop-down-icon {
  width: 12px;
  height: 12px;
  fill: #333;
}

.option {
  padding: 8px 12px;
  background-color: #fff;
  color: #333;
  font-size: 14px;
}

.select-tag:focus {
  border-color: var(--border-focus);
  box-shadow: 0 0 0 1px var(--border-focus);
}
