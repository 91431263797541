.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 20px);
  // background: #ffffff;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
}

.steps-container {
  display: flex;
  gap: 30px;
  margin-left: 20px;
  padding: 0 40px;
  height: 60px;

  .step {
    flex: 100%;
    padding: 10px 0;
    margin: 0 0 0 -19px;
    clip-path: polygon(
      20px 50%,
      0% 0%,
      calc(100% - 20px) 0%,
      100% 50%,
      calc(100% - 20px) 100%,
      0% 100%
    );
    display: flex;
    align-items: center;
    user-select: none;

    &:first-child {
      clip-path: polygon(
        0% 0%,
        calc(100% - 20px) 0%,
        100% 50%,
        calc(100% - 20px) 100%,
        0% 100%
      );
    }

    p {
      font-size: 14px;
      padding: 0 50px;
    }
  }
}
.form-container {
  flex: 1;
  display: flex;
  overflow-y: auto;
}

@media screen and (max-width: 1200px) {
  .steps-container {
    gap: 20px;
    .step {
      flex: auto;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    height: 100%;
    background: #ffffff;
    border-radius: 5px;
    padding: 15px 0;
    margin-bottom: 0;
  }
  .steps-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 0;
    padding: 0 15px;

    .step {
      flex: 100%;
      padding: 10px 0;
      margin: 0;
      clip-path: polygon(
        20px 50%,
        0% 0%,
        calc(100% - 20px) 0%,
        100% 50%,
        calc(100% - 20px) 100%,
        0% 100%
      );
      display: flex;
      align-items: center;
      user-select: none;

      &:first-child {
        clip-path: polygon(
          0% 0%,
          calc(100% - 20px) 0%,
          100% 50%,
          calc(100% - 20px) 100%,
          0% 100%
        );
      }

      p {
        font-size: 14px;
        padding: 0 50px;
      }
    }
  }
  .form-container {
    flex: 1;
    display: flex;
    overflow-y: auto;
    padding: 0 15px;
  }
}
