/* DatePicker.css */
.date-picker-container {
  outline: none;
  border: 1px solid var(--border-gray);
  padding: 8px 12px;
  border-radius: 4px;
  /* color: #333; */
  font-size: 14px;
  flex: 1 1;
}

.date-picker-input {
  font-size: 16px;
  border: none;
  border-radius: 8px;
  width: 100%;
}

.selected-date {
  font-size: 16px;
  color: #333;
}
