.container {
  justify-content: space-between;
  padding: 10px;
}

// Header Wrapper
.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  .primary-heading {
    font-size: 1.75rem;
    font-weight: 500;
    color: #333;
  }
}

// Form Wrapper
.form-wrapper {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10px;

  .form-items {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .basic-user-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .input-label {
      display: flex;
      flex-direction: column;
      width: calc(33.333% - 1rem);

      .label {
        font-weight: 600;
        margin-bottom: 0.5rem;
        color: #555;
      }

      .input-field {
        padding: 0.75rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1rem;
        color: #333;
        &:focus {
          outline: none;
          border-color: #007bff;
          box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
        }
      }
    }
  }

  .uploads {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .form-wrapper {
      display: flex;
      flex-direction: column;
      // padding: 25px;
      border-radius: 5px;
      margin-top: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      background-color: white;
    }

    .input-label {
      width: 700px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .form-items {
      align-items: start;
      margin-left: 70px;
      padding: 40px;
      width: 90% !important;
    }

    .label {
      padding-right: 10px;
      margin-right: 10px;
      align-items: center;
      text-wrap: nowrap;
    }

    .buttons {
      display: flex;
      text-align: end;
      align-items: end;
    }
    .icon-container {
      display: flex;
      align-items: center;
    }

    .remove-icon {
      color: red;
      cursor: pointer;
      margin-left: 8px;
    }

    .remove-icon:hover {
      color: darkred;
    }
    .basic-user-info {
      display: flex;
      flex-direction: column;
      gap: 60px;
      padding-bottom: 30px;
    }

    .form-publised {
      background-color: rgb(63, 63, 136);
      color: white;
      padding: 10px;
      border-radius: 5px;
      width: 200px;
    }

    .action-wrapper {
      margin-top: 30px;
      text-align: end !important;
      width: 100%;
      padding: 5px;
    }

    .form-saved {
      background-color: #f39c12;

      &:hover {
        background-color: #e67e22;
      }
    }

    .form-published {
      background-color: #28a745;

      &:hover {
        background-color: #218838;
      }
    }
  }
}
