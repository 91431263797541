.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 20px);

  border-radius: 5px;

  min-height: 75vh;
  height: auto;
}

.dealer-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding: 20px 40px 20px 40px;
  background-color: #f9f9f9;
  .wrapper {
    .heading {
      color: #918f8f;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 0px;
    }
    .value {
      font-size: 16px;
      font-weight: 700;
      color: #007abe;
      padding-left: 30px;
    }
  }
}

.mid-container {
  display: flex;

  flex-direction: column;
  padding: 10px;
  background-color: white;

  width: 50%;
}

h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.status-section {
  width: 100%;
}

.follow-date-section {
  width: 100%;
}

.two-tabs-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  padding-left: 50px;
  margin-bottom: 20px;
}

.date-picker-container {
  display: flex;
  align-items: center;
}

.date-picker {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ddd;
  padding-right: 40px; /* Adjust padding to prevent overlap with the icon */
}

.calendar-icon {
  right: 10px;
  pointer-events: none;
  color: #888;
  font-size: 18px;
}

.dropdown {
  width: 100%;
}

.input-label {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
}

.label {
  font-size: 0.9rem;
  font-weight: 500;
  color: #555;
  width: 170px; /* Fixed width for labels to align with inputs */
  text-align: left; /* Align text to the right */
}

select,
input {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  max-width: 400px;
}

input[type="number"] {
  max-width: 150px;
  margin-right: 10px;
}

input[type="number"]:last-of-type {
  margin-right: 0; /* Remove margin from the last input in the row */
}

.-span {
  margin-right: 10px;
}

.heading {
  margin-bottom: 50px;
  margin-left: 30px;
}

/* New Styles for Additional Comments and Save Button */
.textarea {
  width: 100%;
  height: 150px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  max-width: 400px;
}

//   .save-button-section {
//     position: fixed;
//     right: 40px;
//     bottom: 20px;
//   }

.save-button-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 10px 20px 20px 20px;
  gap: 10px;
}

.commentsContainer {
  overflow-y: auto;
  padding: 10px;
  // background-color: #f4f4f4;
  // border: 1px solid #ccc;
  border-radius: 8px;
  // max-height: 400px;
}

.commentBlock {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  position: relative;
  min-height: 60px;
}

.commentBlock Typography {
  margin-bottom: 8px;
  color: #333;
  font-size: 1rem;
}

.dateText {
  position: absolute;
  bottom: 3px;
  right: 10px;
  color: #5d5c5c;
  font-size: 0.75rem !important;
  text-align: right;
}
.dateText2 {
  position: absolute;
  bottom: 3px;
  left: 10px;
  color: #5d5c5c;
  font-size: 0.75rem !important;
  text-align: left;
}

.divider {
  border: none;
  height: 1px;
  background-color: #ddd;
  margin: 10px 0;
}

.noCommentsMessage {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  align-items: center;
  height: 100%; /* Ensure it takes the full height of the parent */
  text-align: center;
  color: #666;
  font-size: 1rem;
}

.add-button-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.save-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #121b5b;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #0056b3;
}

.add-button {
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 30px;
  font-weight: 600;
}

.dealer-info-container {
  background-color: #ffffff;
  border-radius: 10px;
  margin-left: 25px;
}

.dealer-info-text {
  font-size: 18px;
  font-weight: 500;
  // color: #333333;
  color: black;
  line-height: 1.5;
}

.all-container {
  display: flex;
  gap: 10px;
  width: 100%;
  // justify-content: space-around;
}
