.input-field {
  outline: none;
  width: 100%;
  border: 1px solid var(--border-gray);
  padding: 8px 12px;
  border-radius: 4px;
  color: #333;
  font-size: 14px;
  flex: 1;
}

.error-msg {
  color: red;
  font-size: 13px;
  margin-left: 10px;
  margin-top: 5px;
  font-weight: 300;
}
