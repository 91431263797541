.container {
  display: flex;
  border-radius: 5px;
  align-items: center;
  width: max-content;
  height: 40px;
  text-decoration: none;
}

.container:hover {
  cursor: pointer;
}

.leading-icon {
  width: 16px;
  object-fit: contain;
  margin-right: 10px;
}

.button-name {
  font-weight: 500;
  color: var(--white);
  margin-bottom: 0;
}
