.container {
  display: flex;
  padding: 0px 12px;
  background-color: var(--white);
  border: 1px solid var(--border-gray);
  border-radius: 5px;
  align-items: center;
  width: 100%;
  height: 40px;
}

.search-icon {
  width: 16px;
  // height: 16px;
  margin-right: 10px;
}

.search-bar {
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
}
