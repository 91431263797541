.header-wrapper {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
}

.right-side-wrapper,
.left-side-wrapper {
    width: 45%;
}

.select-wrapper {
    display: flex;
    width: 400px;
    flex-direction: column;
    margin-bottom: 20px;
}

.date-formater {
    max-width: 520px;
    max-height: 50px;
}

.text-wrapper {
    width: 500px;
}

.star {
    color: red;
}

.select-desin {
    outline: none;
    width: 400px;
    text-align: start;
    color: #333;
    font-size: 14px;
    flex: 1;
}

.save-button {
    width: 70px;
    background-color: #121b5b !important;
    color: white !important;
    border: none;
    border-radius: 5px;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
}

.selectable-button-container-style {
    border-radius: 8px;
}

.filterDropDown {
    min-width: 200px;
}