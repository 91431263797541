.container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.filters-container {
  display: grid;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  margin-top: 10px;
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 768px) {
    display: grid;
  }
}

.filters-wrapper {
  display: flex;
  column-gap: 8px;

  @media screen and (max-width: 768px) {
    display: grid;
    gap: 8px;
  }
}

.filters-container-second {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  margin-bottom: 10px;
  // border: 1px solid black;
}

.filters-wrapper-between {
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
  margin-right: 8px;
  // border: 1px solid black;
}

.add-new-car-button-container-style {
  padding: 6px 14px;
  background-color: var(--primary-blue);

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-top: 8px;
  }
}

.action-buttons-wrapper {
  display: flex;
  column-gap: 12px;
}

// .search-cont{
//   border: 1px solid red;
//   width: 400px;
//   height: 20px;
// }

.search-mobile {
  background-color: red !important;
  height: 30px;

}

.filterDropDown {
  min-width: 200px;
}

.pagination-action-wrapper {
  border-radius: 40px;
  background: #007bff;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  gap: 25px;
  button {
    background: transparent;
    border: none;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;

    &:disabled {
      color: #c7c7c7;
    }
  }
  p {
    color: #ffffff;
    font-size: 14px;
  }
}

.bulk-upload-route-button {
  font-size: 17px;
  padding: 10px;
  margin-left: 10px;
  border-radius: 6px;
  background-color: #228B22;
  display: flex;
  color: white;
  align-items: center;
  gap: 10px;
  border: 5px #ccc;
  font-weight: normal;
  button {
    background: transparent;
    border: none;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
  }
}

.search-action-wrapper {
 border-radius: 5px;
 
  background-color: #121b5b;
  margin-top: 2px;
  padding: 5px 20px;
  display: flex;
  color: white;
  align-items: center;
  gap: 10px;
  border: 5px #ccc;
  font-weight: normal;
  button {
    background: transparent;
    border: none;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
  }
}

.search-action-wrapper-disabled {
  border-radius: 5px;
  // background: var(--primary);
  background-color: #cccccc;
  padding: 5px 20px;
  display: flex;
  color: #666666;
  align-items: center;
  gap: 10px;
  border: 5px #ccc;
  font-weight: normal;
  button {
    background: transparent;
    border: none;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
  }
}
.search-action-wrapper-nonactive {
  border-radius: 10px;
  // background: var(--primary);
  background-color: #ffffff;
  padding: 5px 20px;
  display: flex;
  color: black;
  align-items: center;
  gap: 10px;
  border: 5px #ccc;
  font-weight: normal;
  button {
    background: transparent;
    border: none;
    color: #000;
    font-weight: bold;
    cursor: pointer;
  }
}

.customDateInputBox {
  display: flex;
  align-items: center;
  border: 1px solid gray;
  padding: 6px;
  border-radius: 8px;
  background-color: #fff;
  gap: 10px;
  //width: 120px;
}

.customDateText {
  margin-right: 8px;
  color: #888;
}

.calendarIcon {
  font-size: 14px;
  color: #888;
}

.table-pagination-container {
  padding: 25px;
  display: flex;
  justify-content: space-between;

  .items-count {
    border-radius: 40px;
    background: #d9d9d9;
    padding: 5px 10px;
    font-size: 14px;
  }
}
.table-header-wrapper {
  color: var(--table-header-black);
  background-color: var(--table-header-back);
  border-radius: 120px !important;
  padding: 12px;
  // border: solid 1px  blue;
}

.table-header {
  font-size: 14px;
  font-weight: 600;
  background-color: #f1f4f9 !important;
  border-bottom: 0px;
}

.table-body {
  color: var(--sidebar-text-gray);
}

.table-row {
  font-size: 0.875rem;
  color: var(--original-price);
}

.selectable-button-container-style {
  border-radius: 8px;
}

.selectable-button-container-st {
  border-radius: 8px;
  width: 250px;
}

.selectable-button-container {
  border-radius: 8px;
  width: 300px;
}

.date-change {
  padding: 6px;
  border: 1px solid #ccc;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  ::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #ccc;
  }
}

.header-container {
  // border: 1px solid black;
  display: flex;
  justify-content: space-between;
}


.roleName {
  font-size: 0.65rem; 
  color: #888;
  margin-left: 4px;

}

.advance-filter-button-container{
  margin: 10px;
}
