.container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif; /* Material-UI font family */
}

.filters-container {
  display: grid;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  margin-top: 10px;
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 768px) {
    display: grid;
  }
}

.filters-wrapper {
  display: flex;
  column-gap: 8px;

  @media screen and (max-width: 768px) {
    display: grid;
    gap: 8px;
  }
}

.action-buttons-wrapper {
  display: flex;
  column-gap: 12px;
}

.add-new-car-button-container-style {
  padding: 6px 14px;
  background-color: var(--primary-blue);

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-top: 8px;
  }
}

.excel-button-container-style {
  padding: 6px 14px;
  background-color: var(--excel-green);
}

.filterDropDown {
  min-width: 200px;
}

.listed-box{
  margin-top: 10px;
  // display: flex;
  // border:  1px solid black;
}

.pagination-action-wrapper {
  border-radius: 40px;
  background: var(--primary);
  padding: 5px 20px;
  display: flex;
  align-items: center;
  gap: 25px;
}

.pagination-action-wrapper button {
  background: transparent;
  border: none;
  font-weight: bold;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

.pagination-action-wrapper button:first-of-type {
  color: #4d6af9; /* "Back" button color */
}

.pagination-action-wrapper button:last-of-type {
  color: #4d6af9; /* "Next" button color */
}

.pagination-action-wrapper button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.pagination-action-wrapper button:disabled {
  color: #b3b3b3; /* Disabled button color */
}

.table-pagination-container {
  padding: 2px;
  display: flex;
  justify-content: space-between;
}

.items-count {
  // border-radius: 5px;
  // background: #d9d9d9;
  padding: 10px 10px;
  font-size: 14px;
}

.selectable-button-container-style {
  border-radius: 8px;
}

.listed-on-container {
  display: flex;
  align-items: center;
  gap: 10px; 
}


.listed-on-label {
  // font-weight: 500;
  font-size: 16px;
}


.checkbox-container {
  display: flex;
  gap: 15px; 
}

.checkbox-container label {
  display: flex;
  align-items: center;
  font-size: 14px; 
  cursor: pointer;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 8px;
}