// .container {
//     padding: 15px;
//     display: flex;
//     flex-direction: column;
//     flex-wrap: wrap;
//     // border: 1px solid black;
//   }

.container {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.button-all-conatiner {
  display: flex;
  flex-direction: row;
  // border: 1px solid black;
}

.filters-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  margin-top: 10px;
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 768px) {
    display: grid;
  }
}

.filters-wrapper {
  display: flex;

  column-gap: 8px;

  @media screen and (max-width: 768px) {
    display: grid;
    gap: 8px;
  }
}

.button-item {
  height: 100%;
  text-align: center;
  padding-top: 3px;
  width: 100%;
  color: white;
  background-color: #121b5b;
  border: none;
  border-radius: 5px;
}
.expport-button-container {
  margin-right: 15px;
}

.filter-cont {
  display: flex;
  // border: 1px solid black;
}

.action-buttons-wrapper {
  display: flex;
  column-gap: 12px;
}

.add-new-car-button-container-style {
  padding: 6px 14px;
  background-color: #121b5b;
  border: none;
  color: white;
}

.excel-button-container-style {
  padding: 6px 14px;
  background-color: #00b00d;
  border: none;
  // border: 1px solid black;
  height: 43px;
  color: white;
}

.filterDropDown {
  min-width: 200px;
}

.pagination-action-wrapper {
  border-radius: 40px;
  background: var(--primary);
  padding: 5px 20px;
  display: flex;
  align-items: center;
  gap: 25px;
  button {
    background: transparent;
    border: none;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;

    &:disabled {
      color: #c7c7c7;
    }
  }
  p {
    color: #ffffff;
    font-size: 14px;
  }
}

.upload-headar-container {
  display: flex;
  justify-content: "space-between";
  // border: 1px solid black;
}

.table-pagination-container {
  padding: 25px;
  display: flex;
  justify-content: space-between;

  .items-count {
    border-radius: 40px;
    background: #d9d9d9;
    padding: 5px 10px;
    font-size: 14px;
  }
}

.input-field {
  background: #fafafa;
  border-radius: 5px;
  padding: 0 2px;
  display: flex;
  align-items: center;
  min-width: 0;
  flex: 1;
  select {
    width: 100%;
  }
}

.search-field {
  // background: #fafafa;
  border-radius: 5px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  min-width: 0;
  flex: 1;
  // border: 1px solid black;
  select {
    width: 100%;
  }
}
.download-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 16px;
  // font-weight: bold;
  color: white;
  background-color: #228B22;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-transform: none;
  transition: background-color 0.3s ease;
}

.reset-button {
  display: inline-flex;
  align-items: center;

  margin-left: 10px;
  padding: 10px 20px;
  font-size: 16px;
  // font-weight: bold;
  color: white;
  background-color: #121b5b;
  border: none;

  border-radius: 5px;
  cursor: pointer;
  text-transform: none;
  transition: background-color 0.3s ease;
}

.reset-button:hover {
  background-color: #121b5b;
}

.download-button:hover {
  background-color: #4CBB17;
}

.download-button .icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  color: white; /* Ensures the icon color matches the text color */
}

.headar-button-container {
  display: flex;
  width: 700px;
  justify-content: flex-end;
  // border: 1px solid black;
}

.headar-upload-container {
  gap: 10px;
  // width: 350px;
  display: flex;
}

.custom-file-input {
  font-size: 16px;
  padding: 10px; /* Padding for the input */
  cursor: pointer; /* Pointer cursor on hover */
  // border: 2px solid #007bff;
  border-radius: 5px; /* Rounded corners */
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease; /* Smooth transition on hover */
  background-color: #f0f0f0; /* Light background color */
  color: #333; /* Text color */
}

.custom-file-input:hover {
  background-color: #e0e0e0; /* Slightly darker background on hover */
  border-color: #0056b3; /* Darker border color on hover */
}

.upload-icon {
  margin-top: 7px;
  font-size: 1.9rem;
  margin-left: 1px;
  color: #121b5b;
}
.date-change {
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid rgb(218, 215, 215);
}
