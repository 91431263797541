.mid-container {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: white;
  margin-top: 20px;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  height: calc(100% - 20px);
  background: #ffffff;
  border-radius: 5px;
  margin-bottom: 20px;
}
.selectable-button-container-style{
  width: 600px;
}

.dealer-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding: 20px 40px 20px 40px;
  background-color: #f9f9f9;
  .wrapper {
    .heading {
      color: #918f8f;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 0px;
    }
    .value {
      font-size: 16px;
      font-weight: 700;
      color: #007abe;
      padding-left: 30px;
    }
  }
}

h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.two-tabs-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  padding-left: 50px;
  margin-bottom: 20px;
  margin-left: 150px;
}

.input-label {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
}

.label {
  font-size: 1rem;
  font-weight: 600;
  color: #555;
  width: 150px; /* Fixed width for labels to align with inputs */
  text-align: right; /* Align text to the right */
  margin-right: 20px;
}

select,
input {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  max-width: 300px; /* Ensure the input fields have a consistent width */
}

input[type="number"] {
  max-width: 150px;
  margin-right: 10px;
}

input[type="number"]:last-of-type {
  margin-right: 0; /* Remove margin from the last input in the row */
}

.-span {
  margin-right: 10px;
}

.heading {
  margin-bottom: 50px;
  margin-left: 30px;
}

/* New Styles for Additional Comments and Save Button */
.textarea {
  width: 100%;
  height: 150px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical; 
  max-width: 600px;
}

.save-button-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-right: 30px;
  padding-bottom: 20px;
  gap: 10px;
}

.add-button-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.save-button {
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 30px;
  margin-top: 30px;
  font-weight: 600;
}

.add-button {
    padding: 10px 20px;
    font-size: 14px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 30px;
    font-weight: 600;
}



.save-button:hover {
  background-color: #0056b3;
}
