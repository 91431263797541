@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "bootstrap/dist/css/bootstrap.css";
@import url("./theme/colors.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: var(--background-main-gray) center/cover no-repeat fixed;
  height: 100vh;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
label,
p,
textarea,
input,
div,
button {
  margin: 0;
  padding: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: none !important;
}
