.header-wrapper {
  max-width: 400px;
  text-align: start;
  display: flex;
  margin-left: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f5f5f5;
}

.wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.container {
  padding: 10px;
  margin: 0 auto;
}

.form-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.input-wrapper {
  width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  gap: 15px;
  flex-wrap: wrap;
  /* Ensure input fields stack properly on smaller screens */
}

.input-wrapper label {
  font-size: 14px;
  font-weight: 400;
  color: #333;
}

.input-field {
  width: 400px;
  padding: 7px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
  outline: none;
  transition: border-color 0.2s ease-in-out;
}

.select-field {
  width: 400px;
}

.input-field:focus,
.text-area textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.2);
}

.text-area {
  width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.text-area textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
  outline: none;
  resize: vertical;
  min-width: 400px;
  min-height: 100px;
}

.action-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.form-published {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #28a745;
  transition: background-color 0.3s ease;
}

.form-published:hover {
  opacity: 0.9;
  background-color: #218838;
}
