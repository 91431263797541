.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  margin-top: 10px;
  min-height: 500px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.container1 {
  padding: 10px;

  border-radius: 5px;
}

.joditor {
  max-height: 400px;
  margin-bottom: 40px;
  border-radius: 4px;
  padding: 10px;
  margin-left: 50px;
}

.form-container {
  padding: 20px;
  background-color: white;
}

.file_upload_gcp {
  border: 1px solid gray;
  width: 180px;
  padding: 5px;
  border-radius: 4px;
}

.input-wrapper {
  width: 350px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;
}

.about-wrapper {
  width: 900px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header-wrapper {
  display: flex;
  width: 300px;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.customer_name {
  padding: 5px;
}

.action-container {
  width: 1100px;
  margin-top: auto; /* Push the actions to the bottom */
}

.action-wrapper {
  width: 900px;
  justify-content: space-between;
  text-align: end;
  margin-bottom: 20px;
}

.logo-header {
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-header a {
  display: inline-block;
  margin-left: 10px;
  color: #000;
  transition: color 0.3s ease;
}

.logo-header a:hover {
  color: #007bff;
}

.next-button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
}
