.container {
   background-color: var(--background-main-gray);
   display: flex;
   flex-direction: column;
   padding: 25px;
}

.overview-box-container {
   display: flex;
   width: 100%;
   margin: 30px 0 40px 0;
   column-gap: 18px;
}

