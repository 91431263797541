.container {
   display: flex;
   flex-direction: column;
   padding: 15px 20px;
   flex: 1 0 20%;
   background-color: var(--white);
   border-radius: 12px;
   box-shadow: 6px 6px 54px 0 #0000000D;
}

.upper-section {
   display: flex;
   justify-content: space-between;
}

.text-section {
   display: flex;
   flex-direction: column;
   row-gap: 10px;
}

.title {
   font-size: 1rem;
   color: var(--sidebar-text-gray);
}

.value {
   font-size: 1.5rem;
   font-weight: 600;
   color: var(--primary-blue);
}

.img {
   width: 50px;
   height: 50px;
}

.change-wrapper {
   display: flex;
   align-items: center;
   font-size: 0.875rem;
   font-weight: 600;
   color: var(--card-gray);
   margin-top: 15px;
}

.chart-icon {
   width: 20px;
   object-fit: contain;
   margin: 0 4px;
}