.vehicle-table-container {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 6px 4px 54px 0 #0000000d;
  background-color: var(--white);
  width: 100%;
  // border: solid 1px black;
}

.table-header-wrapper {
  background-color: #f1f4f9;
}

.addCommentText {
  margin-left: 5px;
  font-size: 14px;
  cursor: pointer;
  color: #007bff; /* or any color of your choice */
}

.pref-button {
  width: 30px;
  height: 30px;
  padding-bottom: 2px;
  cursor: pointer;
}

.addCommentButton {
  margin-left: 5px;
  border: none;
  background-color: transparent;
  margin-right: 5px;
  margin-bottom: 0px;
}

.calendarIcon {
  margin-left: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}

.processButton {
  background-color: #121b5b;
  border: none;
  color: white;
  padding: 5px;
  margin-right: 5px;
  border-radius: 5px;
}

.table-header-wrapper {
  color: var(--table-header-black);
  background-color: var(--table-header-back);
  border-radius: 120px !important;
  padding: 12px;
  // border: solid 1px  blue;
}

.table-header {
  font-size: 14px;
  font-weight: 600;
  background-color: #f1f4f9 !important;
  border-bottom: 0px;
}

.table-body {
  color: var(--sidebar-text-gray);
}

.table-row {
  font-size: 0.875rem;
  color: var(--original-price);
}




/* Styling the comment text */
.commentText {
  font-size: 16px;
  line-height: 1.5;
  color: #333; /* Dark text for readability */
  margin-bottom: 5px; /* Space between comment and date */
  background-color: #ffffff; /* White background for comment */
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Styling the date and time */
.dateText {
  font-size: 14px;
  color: #888;
  margin-top: 5px;
  font-style: italic;
}

.dateText2{
  font-size: 14px;
  color: #888;
  margin-top: 5px;
  font-style: Roboto;
}

.divider {
  margin-top: 15px;
  border: 1px solid #ccc;
  height: 1px;
  background-color: #ccc;
}

.no-data-row {
  text-align: center;
  color: gray;
  font-style: italic;
  padding: 10px;
  font-weight: 500;
}


.commentsContainer {
  overflow-y: auto;
  padding: 10px;
  // background-color: #f4f4f4;
  // border: 1px solid #ccc;
  border-radius: 8px;
  // max-height: 400px;
}


.commentBlock {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  position: relative;
  min-height: 60px;
}

.commentBlock Typography {
  margin-bottom: 8px;
  color: #333;
  font-size: 1rem;
}

.dateText {
  position: absolute;
  bottom: 3px;
  right: 10px;
  color: #5d5c5c;
  font-size: 0.75rem !important;
  text-align: right;
}
.dateText2{
  position: absolute;
  bottom: 3px;
  left: 10px;
  color: #5d5c5c;
  font-size: 0.75rem !important;
  text-align: left;

}

.divider {
  border: none;
  height: 1px;
  background-color: #ddd;
  margin: 10px 0;
}


.noCommentsMessage {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  align-items: center;
  height: 100%; /* Ensure it takes the full height of the parent */
  text-align: center;
  color: #666;
  font-size: 1rem;
}

.telecaller-select:disabled {
  color: #666666;
  background-color: #f5f5f5; 
  cursor: not-allowed; 

}

.updatedByRole {
  font-size: 0.8rem;
  color: #6c757d; 
  margin-top: 10px; 
  // display: block; 
  text-align: left;
}


.updatedBy {
  font-weight: bold;
  font-style: italic;
  color: #333; 
}

.roleName {
  font-style: italic; 
  color: #6c757d; 
}



.text-green-500{
 color: green;
 font-size: 17px;
 margin-left: 3px;
}

.text-purple-500{
  margin-right: 3px;
}

.tool-tip{
  border:  1px solid black;
}