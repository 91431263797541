.container {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 30px;
   height: 22px;
   text-decoration: none;
}

.container:hover {
   cursor: pointer;
}

.icon {
   width: 80%;
   object-fit: contain;
}