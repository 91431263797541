.vehicle-table-container {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 6px 4px 54px 0 #0000000d;
  margin-top: 10px;
  background-color: var(--white);
  width: 100%;
}

.table-header-wrapper {
  background-color: #f1f4f9;
}

.table-header {
  font-size: 1rem;
  font-weight: 600;
  background-color: #f1f4f9 !important;
  border-bottom: 0px;
}

.edit-button {
  cursor: pointer;
}

.table-header-wrapper {
  color: var(--table-header-black);
  background-color: var(--table-header-back);
  border-radius: 120px !important;
  padding: 12px;
}

.table-body {
  color: var(--sidebar-text-gray);
}

.table-row {
  font-size: 0.875rem;
  color: var(--original-price);
}

.table-pagination-container {
  padding: 25px;
  display: flex;
  justify-content: space-between;

  .items-count {
    border-radius: 40px;
    background: #d9d9d9;
    padding: 5px 10px;
    font-size: 14px;
  }

  .pagination-action-wrapper {
    border-radius: 40px;
    background: var(--primary);
    padding: 5px 20px;
    display: flex;
    align-items: center;
    gap: 25px;
    button {
      background: transparent;
      border: none;
      color: #ffffff;
      font-weight: bold;
      cursor: pointer;

      &:disabled {
        color: #c7c7c7;
      }
    }
    p {
      color: #ffffff;
      font-size: 14px;
    }
  }
}

.pagination-action-wrapper {
  border-radius: 40px;
  background: var(--primary);
  padding: 5px 20px;
  display: flex;
  align-items: center;
  gap: 25px;
  justify-content: center; /* Center the pagination actions horizontally */
  margin-top: 20px; /* Space between table and pagination */
}

.pagination-action-wrapper button {
  background: transparent;
  border: none;
  color: #c4b5b5;
  font-weight: bold;
  cursor: pointer;
  padding: 8px 16px; /* Add some padding to buttons for better click area */
  border-radius: 20px; /* Rounded corners for buttons */
  transition: background 0.3s, color 0.3s; /* Smooth transition for background and color */
}

.pagination-action-wrapper button:hover {
  background: #c4b5b5; /* Change background on hover */
  color: #fff; /* Change text color on hover */
}

.pagination-action-wrapper button:disabled {
  color: #c7c7c7;
  cursor: not-allowed; /* Change cursor to indicate disabled state */
}

.pagination-action-wrapper p {
  color: #998989;
  font-size: 14px;
  margin: 0; /* Remove default margin from p element */
}
